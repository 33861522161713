import * as actionTypes from '../../actions/actionTypes';
import { descendingOrderSort } from '../../../utils/methods';
import {isNull} from 'lodash';
import {lifeStyle, health, stress, nutrition, workPlace, fitness} from '../../../utils/constants'
const initialState = {
  response: null,
  error: null,
  isLoading: true,
  challengeDetails: null,
  successResponse: null,
  createChallengeError: null,
  getChallengeError: null,
  successMessage: null,
  joinOrLeaveError: null,
  commentSuccess: null,
  commentError: null,
  comments: null,
  postCommentError: null,
  activeTab: 'new',
  networkError: null,
  challengeCompetitors: [],
  challengeCompetitorsError: null,
  replySuccess: null,
  replyError: null,
  featureChallenges: [],
  companyHealth: null,
  healthError: null,
  teamChallenge: null,
  teamChallengeError: null,
  allCompanies: null,
  weekDashboard: 1,
  behaviorChallengeDetaillsById: null,
  challengeAttendees: [],
  syncTime: null,
  competitorsCount: null,
  userChallengeDetails: {},
  challengeCsvData: [],
  challengeCSVStatus: {},
  behaviourHealthData: [],
  behaviourFitnessData: [],
  behaviourStressData: [],
  behaviourLifestyleData: [],
  behaviourNutritionData: [],
  behaviourWorkplaceData: [],
  behaviourChallengeDetails: {},
  activeChallengeCompany: [],
  upcomingChallenge: [],
  userBehaviorChallenge: [],
  liveChallengeStatus: false,
  activityChallengeLibraries: [],
  activityDetailsById: {},
  challengeSubcategory: [],
  challengelibrarySubCategories: [],
  runningStatus: false,
  userActivityChallenges: [],
  activityStatus: false,
  activityChallenge: {},
  isDummyChallenge: false,
  inviteAmigoStatus : false,
  challengeId: null,
  inviteAmigosList: [],
  inviteLoading: false,
  amigosList: [],
  userRecommendedChallengeData: [],
  userRecommendedWellnessChallengeData:[],
  successInviteMessage: null,
  activityChallengeById: {},
  participantCount: {},
  teamChallenges:[],
  teamChallengeDetails:null,
  getTeamChallengeError:null,
  deleteChallenge:null,
  challengeByType:null,
  activityChallengeType:null,
  deleteChallengeLibrary:null,
  behaviorChallengeByType:[]
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

  case actionTypes.FETCH_BEHAVIOR_CHALLENGE_BY_ID: {
    return {
      ...state,
      behaviorChallengeDetaillsById: action.data,
      liveChallengeStatus: action.liveChallengeStatus,
      runningStatus: false
    }
  }

  case actionTypes.USER_RECOMMENDED_CHALLENGE:
    return {
      ...state,
      userRecommendedChallengeData: action.userRecommendedChallengeData
    };
  
  case actionTypes.USER_RECOMMENDED_WELLNESS_CHALLANGE:
    return {
      ...state,
      userRecommendedWellnessChallengeData: action.userRecommendedWellnessChallengeData
    }; 

  case actionTypes.CHANGE_WEEK_DASHBOARD: {
    return {
      ...state,
      weekDashboard: action.value
    }
  } 

  case actionTypes.GET_FEATURED_CHALLENGE:
    return {
      ...state,
      featureChallenges: state.featureChallenges
    };
  
  case actionTypes.SET_FEATURED_CHALLENGE:
    return {
      ...state,
      featureChallenges: action.featuredChallenges,
      activeChallengeCompany: action.activeChallengeCompany,
      upcomingChallenge:action.upcomingChallenge,
      isLoading: false,
    };

  case actionTypes.SET_TEAM_CHALLENGE:
    return {
      ...state,
      teamChallenges: action.teamChallenges,
      isLoading: false,
    };

  case actionTypes.GET_CHALLENGES_DATA:
    return {
      ...state,
      response: action.response,
      isLoading: false
    };

  case actionTypes.FAILED_GET_CHALLENGES_DATA:
    return {
      ...state,
      errorMessage: action.error,
      isLoading: false
    };

  case actionTypes.SUCCESSFULLY_CREATED_CHALLENGE:
    return {
      ...state,
      isLoading: false,
      successResponse: action.successResponse,
      createChallengeError: null
    };

  case actionTypes.FAILED_CREATE_CHALLENGE:
    return {
      ...state,
      isLoading: false,
      createChallengeError: action.error,
    };

  case actionTypes.CHALLENGE_LOADING:
    return {
      ...state,
      isLoading: true
    };

  case actionTypes.GET_CHALLENGE_DETAILS:
    return {
      ...state,
      challengeDetails: {
        ...action.challengeDetails,
        'is_joined': action.challengeDetails.is_joined === 1 ? 1 : 0
      }
    };

  case actionTypes.EMPTY_CHALLENGE_DETAILS:
    return {
      ...state,
      challengeDetails: null
    };

  case actionTypes.FAILED_GET_CHALLENGE_DETAILS:
    return {
      ...state,
      getChallengeError: action.getChallengeError,
    };

  case actionTypes.GET_TEAM_CHALLENGE_DETAILS:
    return {
      ...state,
      teamChallengeDetails: {
        ...action.teamChallengeDetails,
      }
    };
  
  case actionTypes.EMPTY_TEAM_CHALLENGE_DETAILS:
    return {
      ...state,
      teamChallengeDetails: null
    };
  
  case actionTypes.FAILED_GET_TEAM_CHALLENGE_DETAILS:
    return {
      ...state,
      getTeamChallengeError: action.getChallengeError,
    };

  case actionTypes.JOIN_LEAVE_CHALLENGE_SUCCESS:
    return {
      ...state,
      successMessage: action.success,
      isLoading: false
    };

  case actionTypes.JOIN_LEAVE_CHALLENGE_FAILED:
    return {
      ...state,
      joinOrLeaveError: action.joinOrLeaveError,
      isLoading: false
    };

  case actionTypes.GET_COMMENTS_ON_CHALLENGE:

    return {
      ...state,
      comments: descendingOrderSort(action.comments),
      isLoading: false
    };

  case actionTypes.FAILED_GET_COMMENTS_ON_CHALLENGE:
    return {
      ...state,
      commentError: action.commentError,
      isLoading: false
    };

  case actionTypes.ADD_COMMENT_ON_CHALLENGE:
    return {
      ...state,
      commentSuccess: action.commentSuccess
    };

  case actionTypes.FAILED_ADD_COMMENT_ON_CHALLENGE:
    return {
      ...state,
      postCommentError: action.postCommentError
    };

  case actionTypes.GET_ACTIVE_TAB:
    return {
      ...state,
      activeTab: state.activeTab
    };

  case actionTypes.SET_ACTIVE_TAB:
    return {
      ...state,
      activeTab: action.activeTab
    };

  case actionTypes.NETWORK_ERROR:
    return {
      ...state,
      networkError: action.networkError,
      isLoading: false
    };

  case actionTypes.SAVE_CHALLENGE_DATA:
    return {
      ...state,
      challengeData: action.challengeData
    };

  case actionTypes.GET_CHALLENGE_DATA:
    return {
      ...state,
    };

  case actionTypes.GET_CHALLENGE_COMPETITORS:{
    let response = [];
    if (action.pageNumber === 1) {
      response = isNull(action.challengeCompetitors) ? [] : action.challengeCompetitors;
    } else {
      let data = isNull(action.challengeCompetitors) ? [] : action.challengeCompetitors;
      response = [
        ...state.challengeCompetitors,
        ...data
      ];
    }
    return {
      ...state,
      challengeCompetitors: response,
      competitorsCount: action.count,
    };
  }

  case actionTypes.FAILED_GET_CHALLENGE_COMPETITORS:
    return {
      ...state,
      challengeCompetitorsError: action.challengeCompetitorsError,
      isLoading: false
    };

  case actionTypes.ADD_COMMENT_REPLY_ON_CHALLENGE:
    return {
      ...state,
      replySuccess: action.replySuccess
    };

  case actionTypes.FAILED_ADD_COMMENT_REPLY_ON_CHALLENGE:
    return {
      ...state,
      replyError: action.replyError
    };

  case actionTypes.GET_COMPANY_HEALTH_DETAILS:
    return {
      ...state,
      companyHealth: action.companyHealth
    };

  case actionTypes.FAILED_GET_COMPANY_HEALTH_DETAILS:
    return {
      ...state,
      healthError: action.healthError
    };

  case actionTypes.GET_TEAM_CHALLENGE:
    return {
      ...state,
      teamChallenge: action.teamChallenge
    };

  case actionTypes.FAILED_GET_TEAM_CHALLENGE:
    return {
      ...state,
      teamChallengeError: action.teamChallengeError
    };

  case actionTypes.GET_ALL_COMPANIES:
    return {
      ...state,
      allCompanies: action.allCompanies
    };
  case actionTypes.GET_USER_LIST_OF_CHALLENGE:
    return {
      ...state,
      challengeAttendees: action.challengeAttendees
    };
  case actionTypes.GET_USER_LAST_SYNC:
    return {
      ...state,
      syncTime: action.syncTime
    };
  case actionTypes.GET_USER_OVERVIEW_DETAILS:
    return {
      ...state,
      userChallengeDetails: action.userChallengeDetails
    };
  case actionTypes.GET_CHALLENGE_CSV:
    return {
      ...state,
      challengeCsvData: action.challengeCsvData
    };
  case actionTypes.GET_CHALLENGE_DOWNLOAD_CSV_STATUS:
    return {
      ...state,
      challengeCSVStatus: action.challengeCSVStatus
    };
  case actionTypes.FETCH_BEHAVIOR_CHALENGE_DATA_BY_CATEGORY: {
    if(action.categoryName === lifeStyle) {
      return {
        ...state,
        behaviourLifestyleData: action.behaviourCategotyData
      };
    } else if(action.categoryName === health){
      return {
        ...state,
        behaviourHealthData: action.behaviourCategotyData
      };
    }else if(action.categoryName === stress){
      return {
        ...state,
        behaviourStressData: action.behaviourCategotyData
      };
    }else if(action.categoryName === nutrition){
      return {
        ...state,
        behaviourNutritionData: action.behaviourCategotyData
      };
    }else if(action.categoryName === workPlace){
      return {
        ...state,
        behaviourWorkplaceData: action.behaviourCategotyData
      };
    }else if(action.categoryName === fitness){
      return {
        ...state,
        behaviourFitnessData: action.behaviourCategotyData
      };
    }
    return {
      ...state
    }
  }
  case actionTypes.FETCH_BEHAVIOR_CHALLENGE_DETAILS:
    return {
      ...state,
      behaviourChallengeDetails: action.behaviourChallengeDetails
    };
  case actionTypes.GET_USER_BEHAVIOR_CHALLENGES:
    return {
      ...state,
      userBehaviorChallenge: action.userBehaviorChallenge,
      isLoading: false
    };
  case actionTypes.UPDATE_LIVE_CHALLENGES_STATUS:
    return {
      ...state,
      liveChallengeStatus: false,
      runningStatus: false,
      activityStatus: false,
    };
  case actionTypes.FETCH_ACTIVITY_CHALLENGES_LIBRARY:
    return {
      ...state,
      activityChallengeLibraries: action.activityChallengeLibraries,
    };
  case actionTypes.FETCH_ACTIVITY_CHALLENGE_BY_ID:
    return {
      ...state,
      activityDetailsById: action.activityDetailsById,
      isLoading: false
    };
  case actionTypes.FETCH_CHALLENGE_SUBCATEGORY:
    return {
      ...state,
      challengeSubcategory: action.challengeSubcategory,
      isLoading: false
    };
  case actionTypes.FETCH_CHALLENGE_DETAILS_BY_SUBCATEGORY:
    return {
      ...state,
      challengelibrarySubCategories: action.challengelibrarySubCategories,
      runningStatus: action.runningStatus,
      runningSubCategory: action.runningSubCategory,
      isLoading: false
    }
  case actionTypes.GET_USER_ACTIVITY_CHALLENGES:
    return {
      ...state,
      userActivityChallenges: action.userActivityChallenges,
      isLoading: false
    }
  case actionTypes.SET_USER_LIVE_CHALLENGES_STATUS:
    return {
      ...state,
      liveChallengeStatus: true,
      activityStatus: true,
      activityChallenge: action.activityChallenge
    }
  case actionTypes.TOGGLE_DUMMY_CHALLENGES:{
    return {
      ...state,
      isDummyChallenge: action.isDummyChallenge
    }
  }
  case actionTypes.SET_CHALLENGE_INVITE_STATUS:{
    return {
      ...state,
      inviteAmigoStatus: action.inviteAmigoStatus,
      challengeId: action.challengeId
    }
  }  
  case actionTypes.GET_INVITE_AMIGO_LIST:{
    return {
      ...state,
      inviteAmigosList: action.inviteAmigosList,
      inviteLoading: false
    }
  }
  case actionTypes.INVITE_LOADING:{
    return {
      ...state,
      inviteLoading: true
    }
  }
  case actionTypes.GET_BUDDIES_LIST_OF_CHALLENGES:{
    return {
      ...state,
      amigosList: action.amigosList
    }
  }
  case actionTypes.REMOVE_USER_CHALLENGE_INVITATION:
    return {
      ...state,
      successInviteMessage: action.successInviteMessage,
    };
  case actionTypes.ACTIVITY_CHALLENGE_BY_ID:
    return {
      ...state,
      activityChallengeById: action.activityChallengeById,
      liveChallengeStatus: action.liveChallengeStatus,
      runningStatus: false
    };
  case actionTypes.GET_CHALLENGE_PARTICIPANTS_COUNT:{
    return {
      ...state,
      participantCount: action.participantCount,
    }
  }
  case actionTypes.DELETE_CHALLENGE:{
    return {
      ...state,
      deleteChallenge: action.deleteChallenge
    }
  }
  case actionTypes.DELETE_CHALLENGE_LIBRARY:{
    return {
      ...state,
      deleteChallengeLibrary: action.deleteChallengeLibrary
    }
  }
  case actionTypes.ACTIVITY_CHALLENGE_TYPE:{
    return {
      ...state,
      activityChallengeType: action.activityChallengeType
    }
  }
  case actionTypes.GET_CHALLENGE_TYPE_BY_CHALLENGE:{
    return {
      ...state,
      challengeByType: action.challengeByType
    }
  }
  case actionTypes.BEHAVIOR_CHALLENGE_TYPE:{
    return {
      ...state,
      behaviorChallengeByType: action.behaviorChallengeByType
    }
  }
  default: break;
  }
  return state;
};

export default reducer;

/* eslint-disable no-console, no-undef, no-case-declarations, camelcase */
import * as actionTypes from '../../actions/actionTypes';
import _ from 'lodash';
const initialState = {
  wellnessChampions: null,
  networkError: null,
  companies: null,
  wellnessChampionsError: null,
  wellnessAdmin: null,
  wellnessAdminError: null,
  companiesError: null,
  companyInfo: null,
  newUsers: null,
  mostActiveUsers: null,
  leastActiveUsers: null,
  fitnessGoals: null,
  topDevices: null,
  topActivities: null,
  weekExercise: null,
  wellnessInitiatives: null,
  companyInitiatives: null,
  groupActivitySummary: null,
  employeeCount: null,
  companyPeopleLocation: null,
  companyPeopleDepartment: null,
  companyBlockData: null,
  reportsSmokeData: null,
  reportsPhysicalExamData: null,
  reportsExperiencePain: null,
  reportsPrescriptionDrug: null,
  reportsWellbeingAssessment: null,
  reportsDailyWaterIntake: null,
  reportsMedicalDiagnosis: null,
  reportsDailyAverageSleep: null,
  reportsPainLocation: null,
  reportsSickLastYear: null,
  reportsSugarIntake: null,
  reportsBournoutFrequency: null,
  reportsAlcoholIntake: null,
  showReportTab: null,
  lifeStyleCommunicationData: null,
  lifeStyleDecisionMakingData: null,
  lifeStyleValuableData: null,
  lifeStyleWorkInterferesData: null,
  lifeStyleStandByWorkData: null,
  lifeStyleMinutesExerciseData: null,
  lifeStyleWorkFreeTimeData: null,
  enrolledUsers: [],
  fitnessUsers: [],
  csvButton: null,
  bodyMassDetails: null,
  bodyMassAveragePercentage:null,
  badCholesterolDetails: null,
  badCholesterolAveragePercentage:null,
  goodCholesterolDetails: null,
  goodCholesterolAveragePercentage:null,
  triglyceridesDetails: null,
  // topHealthDetails: null,
  eligibleEmployee: [],
  challengesWellnessCountKey: [],
  companyEducationDetailsKey: [],
  userBirthday: [],
  averageBiometricDetail: null,
  overWeightBodyMass : null,
  recognitionDetails:null,
  employeesOfTheMonthDetails:null,
  mostSocialUsersDetails:null,
  hurdleInEatingData: null,
  stressCausingFactors: null,
  frequencyOfEatingFreshFruits: null,
  frequencyOfEatingDrinkingAddedSugar: null,
  frequencyOfStressAboutYourFinances: null,
  frequencyOfRelaxingDetails: null,
  frequencyOfHighlyProcessedFoodDetails: null,
  frequencyOfEatingHealthyDetails: null,
  getRecognitionActivityDetails: null,
  getTopRecognizedRecognitionDetails: null,
  getTopReceiversRecognitionDetails: null,
  getRecognitionDepartmentDetails: null,
  getRecognitionLocationDetails: null,
  getRecognitionForAllDepartmentsDetails: null,
  getRecognitionForAllLocationsDetails: null,
  getRecognitionDetails: null,
  recognitionLoading: false,
  isLoading: false,
  selectedYearInfo: null,
  selectedYearInfoV2: null,
  wellbeingLoading: false,
  lifeStyleLoading: false,
  healthTabLoading: false,
  fitnessTabLoading: false,
  monthlyTaskIcons: [],
  adminMonthlyTasks: [],
  deleteTaskStatus: {},
  showBiometricStatus: {},
  getCompanyRecognitions: null,
  reportMessage: null,
  companyRewardPrograms: [],
  rewardsReport: [],
  userLanguage: [],
  topEvents: [],
  fitnessLeaderboard: [],
  socialUserData: [],
  fitnessUserData: [],
  wellnessUserData: [],
  topUsersTotalPoints: [],
  companyTeamPoints: [],
  teamUsersPoints: [],
  distanceLeaderboard:[],
  stepsLeaderboard:[],
  caloriesLeaderboard:[],
  userRank:[],
  dailyTipData: null,
  tipCategoryCount: null,
  dailytipData: null,
  todayTipData: null,
  tipQuoteCount: null,
  getLanguages: null,
  employeesOfTheMonthlylyDetails:null,
  employeesOfTheMonthByQuarterlyDetails:null,
  teamIconsData:[],
  teamsCount:null,
  wellnessCount: null,
  companyInfoData: null,
  userTotalPoints: null
};

// const calculateGoodCholesterol = (payload) => [

//   {
//     title: 'High Risk',
//     subTitle: '[0 - 39]',
//     fill: `${payload.high_risk}%`,
//     fillColor: 'rgba(52, 219, 198, 1)',
//     backgroundFill: 'rgba(52, 219, 198, 0.3)'
//   },
//   {
//     title: 'Normal',
//     subTitle: '[40 - 59]',
//     fill: `${payload.normal}%`,
//     fillColor: 'rgba(251, 130, 19, 1)',
//     backgroundFill: 'rgba(251, 130, 19, 0.3)'
//   },
//   {
//     title: 'Lowers Your Risk',
//     subTitle: '[60+]',
//     fill: `${payload.lower_risk}%`,
//     fillColor: 'rgba(89, 183, 230, 1)',
//     backgroundFill: 'rgba(89, 183, 230, 0.3)'
//   }
// ];

const calculateGoodCholesterol = (payload) => [

  {
    title: 'Optimal',
    subTitle: '[67+]',
    fill: `${payload.optimal}%`,
    fillColor: '#76AB78',
    backgroundFill: 'rgba(86, 192, 36, 0.3)'
  },
  {
    title: 'Good',
    subTitle: '[60 - 65]',
    fill: `${payload.good}%`,
    fillColor: '#F1C977',
    backgroundFill: 'rgba(237, 199, 41, 0.3)'
  },
  {
    title: 'Low Normal',
    subTitle: '[60+]',
    fill: `${payload.normal}%`,
    fillColor: '#85C0EA',
    backgroundFill: 'rgba(251, 130, 19, 0.3)'
  },
  {
    title: 'Very Low',
    subTitle: '[60+]',
    fill: `${payload.low_risk}%`,
    fillColor: '#AF87C1',
    backgroundFill: 'rgba(250, 63, 25, 0.3)'
  },
  {
    title: 'At Risk',
    subTitle: '[60+]',
    fill: `${payload.high_risk}%`,
    fillColor: '#F4AAA9',
    backgroundFill: 'rgba(209, 12, 0, 0.3)'
  }
];

const calculateGoodCholesterolAveragePercentage = (payload)=>[
  {
    average_point:`${payload.average_points}%`
  }
]


const calculateBadCholesterol = (payload) => [
  {
    title: 'Optimal',
    subTitle: '[0 - 99]',
    fill: `${payload.optimal}%`,
    fillColor: '#76AB78',
    backgroundFill: 'rgba(86, 192, 36, 0.3)'
  },
  {
    title: 'Near Optimal',
    subTitle: '[100 - 129]',
    fill: `${payload.near_optimal}%`,
    fillColor: '#F1C977',
    backgroundFill: 'rgba(237, 199, 41, 0.3)'
  },
  {
    title: 'Borderline High',
    subTitle: '[130 - 159]',
    fill: `${payload.borderline_high}%`,
    fillColor: '#85C0EA',
    backgroundFill: 'rgba(251, 130, 19, 0.3)'
  },
  {
    title: 'High',
    subTitle: '[160 - 189]',
    fill: `${payload.high}%`,
    fillColor: '#AF87C1',
    backgroundFill: 'rgba(250, 63, 25, 0.3)'
  },
  {
    title: 'Very High',
    subTitle: '[190+]',
    fill: `${payload.very_high}%`,
    fillColor: '#F4AAA9',
    backgroundFill: 'rgba(209, 12, 0, 0.3)'
  }
];

const calculateBadCholesterolAveragePercentage = (payload)=>[
  {
    average_point:`${payload.average_points}%`
  }
]

const calculateBodyMass = (payload) => [
  {
    title: 'Underweight',
    subTitle: 'BMI IS < 18.5',
    fill: `${payload.underweight}%`,
    fillColor: 'rgba(86, 192, 36, 1)',
    backgroundFill: 'rgba(86, 192, 36, 0.3)'
  },
  {
    title: 'Normal',
    subTitle: 'BMI IS 18.5 - 24.9',
    fill: `${payload.normal}%`,
    fillColor: 'rgba(237, 199, 41, 1)',
    backgroundFill: 'rgba(237, 199, 41, 0.3)'
  },
  {
    title: 'Overweight',
    subTitle: 'BMI IS 25 - 29.9',
    fill: `${payload.overweight}%`,
    fillColor: 'rgba(251, 130, 19, 1)',
    backgroundFill: 'rgba(251, 130, 19, 0.3)'
  },
  {
    title: 'Obese',
    subTitle: 'BMI IS 30+',
    fill: `${payload.obese}%`,
    fillColor: 'rgba(250, 63, 25, 1)',
    backgroundFill: 'rgba(250, 63, 25, 0.3)',
  }
];


const calculateBodyMassAveragePercentage = (payload)=>[
  {
    average_point:`${payload.average_points}%`
  }
]




// const calculateAverageBiometric = (payload) => {
//   const bloodSugar =  payload['glucose_fasting'] + payload['glucose_non_fasting'];
//   return [
//     {
//       title: 'TOTAL BIOMETRICS',
//       value: payload['total_biometric'],
//       status: '',
//       subTitle: 'Biometric Completion',
//       marginLeft:"0px",
//       marginRight:"8px",
//       childNumber:"firstChild",
//       imageUrl: '/images/CompanyDashBoardV2/fingerPrint.png'
//     },
//     {
//       title: 'BLOOD PRESSURE',
//       value: payload['blood_pressure'],
//       status: 'Normal',
//       subTitle: 'Blood Pressure',
//       childNumber: "secondChild",
//       marginLeft:"8px",
//       marginRight:"8px",
//       imageUrl: '/images/CompanyDashBoardV2/bloodPressure.png'
//     },
//     {
//       title: 'BODY MASS',
//       value: payload['body_mass_index'],
//       status: 'Normal',
//       subTitle: 'Body Mass Index',
//       childNumber:"thirdChild",
//       marginLeft:"8px",
//       marginRight:"8px",
//       imageUrl: '/images/CompanyDashBoardV2/bodyMass.png'
//     },
//     {
//       title: 'BODY MASS',
//       value: '',
//       status: 'Obese',
//       subTitle: 'Body Mass Index',
//       childNumber:"fourthChild",
//       marginLeft:"8px",
//       marginRight:"0px",
//       imageUrl: '/images/CompanyDashBoardV2/bodyMass-overweight.png'
//     },
//     {
//       title: 'TRYGLYCERIDES',
//       value: payload['triglycerides'],
//       status: 'Normal',
//       subTitle: 'Triglycerides',
//       childNumber:"firstChild",
//       marginLeft:"0px",
//       marginRight:"8px",
//       imageUrl: '/images/CompanyDashBoardV2/trygly.png'
//     },
//     {
//       title: 'Blood Sugar',
//       value: `${bloodSugar}`,
//       status: 'Pre-Diabetic',
//       subTitle: 'Fasting and Non-fasting',
//       marginLeft:"8px",
//       marginRight:"8px",
//       childNumber:"secondChild",
//       imageUrl: '/images/CompanyDashBoardV2/blood-sugar.png'
//     },
//     {
//       title: 'HDL',
//       value: payload['hdl'],
//       status: 'Normal',
//       subTitle: 'HDL Cholesterol',
//       childNumber:"thirdChild",
//       marginLeft:"8px",
//       marginRight:"8px",
//       imageUrl: '/images/CompanyDashBoardV2/hdl.png'
//     },
//     {
//       title: 'LDL',
//       value: payload['ldl'],
//       status: 'Optimal',
//       subTitle: 'LDL Cholesterol',
//       childNumber:"fourthChild",
//       marginLeft:"8px",
//       marginRight:"0px",
//       imageUrl: '/images/CompanyDashBoardV2/ldl.png'
//     }
//   ];
// }



const calculateAverageBiometric = (payload) =>[
  {
    title:'Overall Company Health Score',
    value: payload['overall_score'],
  }];



const calculateSmokeValue = (payload) => [
  {
    title: 'Yes',
    fill1: `${payload.male[0]}%`,
    label1: payload.male[0],
    fillColor1: '#0c99c1',
    backgroundFill1: '#cceaef',
    fill2: `${payload.female[0]}%`,
    fillColor2: '#c43178;',
    backgroundFill2: '#f0dae2',
    label2: payload.female[0]
  },
  {
    title: 'Casual Yes',
    fill1: `${payload.male[1]}%`,
    label1: payload.male[1],
    fillColor1: '#0c99c1',
    backgroundFill1: '#cceaef',
    fill2: `${payload.female[1]}%`,
    fillColor2: '#c43178;',
    backgroundFill2: '#f0dae2',
    label2: payload.female[1]
  },
  {
    title: 'No',
    fill1: `${payload.male[2]}%`,
    label1: payload.male[2],
    fillColor1: '#0c99c1',
    backgroundFill1: '#cceaef',
    fill2: `${payload.female[2]}%`,
    fillColor2: '#c43178;',
    backgroundFill2: '#f0dae2',
    label2: payload.female[2],
  }
];

const calculatePhysicalFrequency = (payload) => [
  {
    title: 'Every Year',
    fill: `${payload[0]}%`,
    fillColor1: '#0c99c1',
    backgroundFill1: '#cceaef',
    label: payload[0]
  },
  {
    title: 'Every 1+ Years',
    fill: `${payload[1]}%`,
    fillColor1: '#0c99c1',
    backgroundFill1: '#cceaef',
    label: payload[1]
  },
  {
    title: 'Every 3+ Years',
    fill: `${payload[2]}%`,
    fillColor1: '#0c99c1',
    backgroundFill1: '#cceaef',
    label: payload[2]
  },
  {
    title: 'Every 5+ Years',
    fill: `${payload[3]}%`,
    fillColor1: '#0c99c1',
    backgroundFill1: '#cceaef',
    label: payload[3]
  }
];

const calculateAlcoholIntake = (payload) => [
  {
    title: 'Alcohol intake 0 - 1',
    fill: `${payload['alcohol_intake'][0]}%`,
    fillColor1: '#0c99c1',
    backgroundFill1: '#cceaef',
    label: payload['alcohol_intake'][0]
  },
  {
    title: 'Alcohol intake 2',
    fill: `${payload['alcohol_intake'][1]}%`,
    fillColor1: '#0c99c1',
    backgroundFill1: '#cceaef',
    label: payload['alcohol_intake'][1]
  },
  {
    title: 'Alcohol intake 3',
    fill: `${payload['alcohol_intake'][2]}%`,
    fillColor1: '#0c99c1',
    backgroundFill1: '#cceaef',
    label: payload['alcohol_intake'][2]
  },
  {
    title: 'Alcohol intake 4+',
    fill: `${payload['alcohol_intake'][3]}%`,
    fillColor1: '#0c99c1',
    backgroundFill1: '#cceaef',
    label: payload['alcohol_intake'][3]
  }
];

const calculateCommunicationCount = (payload) => [
  {
    title: 'Rarely/Never',
    fill: `${payload[0]}%`,
    fillColor1: '#0c99c1',
    backgroundFill1: '#cceaef'
  },
  {
    title: 'Sometimes',
    fill: `${payload[1]}%`,
    fillColor1: '#0c99c1',
    backgroundFill1: '#cceaef'
  },
  {
    title: 'Most of The Time',
    fill: `${payload[2]}%`,
    fillColor1: '#0c99c1',
    backgroundFill1: '#cceaef'
  },
  {
    title: 'All of The Time',
    fill: `${payload[3]}%`,
    fillColor1: '#0c99c1',
    backgroundFill1: '#cceaef'
  }
];

const calculateExerciseCount = (payload) => [
  {
    title: 'Rarely/Never',
    fill: `${payload[0]}%`,
    fillColor1: '#0c99c1',
    backgroundFill1: '#cceaef'
  },
  {
    title: 'Sometimes',
    fill: `${payload[1]}%`,
    fillColor1: '#0c99c1',
    backgroundFill1: '#cceaef'
  },
  {
    title: 'Most of The Time',
    fill: `${payload[2]}%`,
    fillColor1: '#0c99c1',
    backgroundFill1: '#cceaef'
  },
  {
    title: 'All of The Time',
    fill: `${payload[3]}%`,
    fillColor1: '#0c99c1',
    backgroundFill1: '#cceaef'
  }
];

const calculateFeelingBurnOutData  = (payload) => [
  {
    title: 'Daily',
    fill: `${payload['daily_percentage']}%`,
    fillColor: 'rgba(255,63,25,1)',
    backgroundFill: 'rgba(255,63,25, 0.2)'
  },
  {
    title: 'Few times/week',
    fill: `${payload['few_times_per_week_percentage']}%`,
    fillColor: 'rgba(251,174,25,1)',
    backgroundFill: 'rgba(251,174,25,0.2)'
  },
  {
    title: 'Few times/month',
    fill: `${payload['few_times_per_month_percentage']}%`,
    fillColor: 'rgba(100,204,236,1)',
    backgroundFill: 'rgba(100,204,236,0.2)'
  },
  {
    title: 'Rarely or Never',
    fill: `${payload['rarely_or_never_percentage']}%`,
    fillColor: 'rgba(148,191,62,1)',
    backgroundFill: 'rgba(148,191,62,0.2)'
  }
];

const calculateWorkInterferesData = (payload) => [
  {
    title: 'Daily',
    fill: `${payload['daily_percentage']}%`,
    fillColor: 'rgba(255,63,25,1)',
    backgroundFill: 'rgba(255,63,25, 0.2)'
  },
  {
    title: 'Few times/week',
    fill: `${payload['few_times_per_week_percentage']}%`,
    fillColor: 'rgba(251,174,25,1)',
    backgroundFill: 'rgba(251,174,25,0.2)'
  },
  {
    title: 'Few times/month',
    fill: `${payload['few_times_per_month_percentage']}%`,
    fillColor: 'rgba(100,204,236,1)',
    backgroundFill: 'rgba(100,204,236,0.2)'
  },
  {
    title: 'Rarely or Never',
    fill: `${payload['rarely_or_never_percentage']}%`,
    fillColor: 'rgba(148,191,62,1)',
    backgroundFill: 'rgba(148,191,62,0.2)'
  }
];

const calculateHealthLimitingPerformance = (payload) => [
  {
    title: 'Daily',
    fill: `${payload['daily_percentage']}%`,
    fillColor: 'rgba(255,63,25,1)',
    backgroundFill: 'rgba(255,63,25, 0.2)'
  },
  {
    title: 'Few times/week',
    fill: `${payload['few_times_per_week_percentage']}%`,
    fillColor: 'rgba(251,174,25,1)',
    backgroundFill: 'rgba(251,174,25,0.2)'
  },
  {
    title: 'Few times/month',
    fill: `${payload['few_times_per_month_percentage']}%`,
    fillColor: 'rgba(100,204,236,1)',
    backgroundFill: 'rgba(100,204,236,0.2)'
  },
  {
    title: 'Rarely or Never',
    fill: `${payload['rarely_or_never_percentage']}%`,
    fillColor: 'rgba(148,191,62,1)',
    backgroundFill: 'rgba(148,191,62,0.2)'
  }
]

const reducer = (state = initialState, action) => {
  switch (action.type) {
  
  case actionTypes.SET_WORK_IN_FREE_TIME:
    return {
      ...state,
      lifeStyleWorkFreeTimeData: action.data,
    }

  case actionTypes.GET_ENROLLED_USERS:
    return {
      ...state,
      enrolledUsers: action.enrolledUsers,
      csvButton: false
    };

  case actionTypes.GET_FITNESS_REPORT:
    return {
      ...state,
      fitnessUsers: action.fitnessUsers,
      csvButton: false
    };

  case actionTypes.GET_ELIGIBLE_EMPLOYEE:
    return {
      ...state,
      eligibleEmployee: action.eligibleEmployee
    }

  case actionTypes.GET_CHALLENGES_AND_WELLNESS_COUNT:
    return {
      ...state,
      challengesWellnessCountKey: action.challengesWellnessCountKey
    }

  case actionTypes.GET_COMPANY_EDUCATION_DETAILS:
    return {
      ...state,
      companyEducationDetailsKey: action.companyEducationDetailsKey
    }
  case actionTypes.GET_USER_BIRTHDAY:
    return {
      ...state,
      userBirthday: action.userBirthday
    }

  case actionTypes.CSV_BUTTON_DISABLED:
    return {
      ...state,
      csvButton: true
    };
   
  case actionTypes.SET_MINUTES_EXERCISE:
    return {
      ...state,
      lifeStyleMinutesExerciseData: !_.isNull(action.data) ? calculateExerciseCount(action.data) : [action.data],
    }
    
  case actionTypes.SET_STAND_BY_WORK:
    return {
      ...state,
      lifeStyleStandByWorkData: action.data,
    } 

  case actionTypes.SET_WORK_INTERFERES:
    return {
      ...state,
      lifeStyleWorkInterferesData: !_.isNull(action.data) ? calculateWorkInterferesData(action.data) : [action.data],
      averagelifestyleInterferers: !_.isNull(action.data) ? action.data['average_percentage'] : '',
      lastYearLifeStyleInterferers: !_.isNull(action.data) ? action.data['last_year_average_percentage'] : ''
    }

  case actionTypes.SET_HEALTH_LIMITING_PERFORMANCE:
    return {
      ...state,
      healthLimitingPerformance: !_.isNull(action.healthLimiting) ? calculateHealthLimitingPerformance(action.healthLimiting) : [action.healthLimiting],
      averageHealthLimitingPerformance: !_.isNull(action.healthLimiting) ? action.healthLimiting : [action.healthLimiting]
    }

  case actionTypes.SET_VALUABLE_PART_OF_TEAM:
    return {
      ...state,
      lifeStyleValuableData: action.data,
    }

  case actionTypes.SET_DECISION_MAKING:
    return {
      ...state,
      lifeStyleDecisionMakingData: action.data,
    }
  
  case actionTypes.SET_COMMUNICATION_TEAM:
    return {
      ...state,
      lifeStyleCommunicationData: !_.isNull(action.data) ? calculateCommunicationCount(action.data) : [action.data],
    }

    /*case actionTypes.SET_DEFAULT_REPORT_TAB:
    return {
      ...state,
      showReportTab: null
    }*/

  case actionTypes.SET_REPORT_TAB: 
    return {
      ...state,
      showReportTab: action.data.show_tab,
      reportMessage: action.data.tab_message
    }

  case actionTypes.FETCH_ALCOHOL_INTAKE: 
    return {
      ...state,
      reportsAlcoholIntake: !_.isNull(action.data) ? calculateAlcoholIntake(action.data) : [action.data],
    }

  case actionTypes.FETCH_BURNOUT_FREQUENCY:
    return {
      ...state,
      reportsBournoutFrequency: !_.isNull(action.data) ? calculateFeelingBurnOutData(action.data) : [action.data],
      averageBurnoutFrequency: !_.isNull(action.data) ? action.data['average_percentage'] : '',
      lastYearBurnoutFrequency: !_.isNull(action.data) ? action.data['last_year_average_percentage'] : ''
    }

  case actionTypes.FETCH_SUGAR_INTAKE:
    return {
      ...state,
      reportsSugarIntake: action.data,
    }

  case actionTypes.FETCH_SICK_LAST_YEAR:
    return {
      ...state,
      reportsSickLastYear: action.data,
    }

  case actionTypes.FETCH_PAIN_LOCATION:
    return {
      ...state,
      reportsPainLocation: action.data,
    }

  case actionTypes.FETCH_DAILY_AVERAGE_SLEEP:
    return {
      ...state,
      reportsDailyAverageSleep: action.data
    }  

  case actionTypes.FETCH_MEDICAL_DIAGNOSIS:
    return {
      ...state,
      reportsMedicalDiagnosis: action.data,
    }
  
  case actionTypes.FETCH_DAILY_WATER_INTAKE: 
    return {
      ...state,
      reportsDailyWaterIntake: action.data
    }  
    
  case actionTypes.FETCH_WELLBEING_ASSESSMENT_REPORTS: 
    return {
      ...state,
      reportsWellbeingAssessment: action.data,
    }  

  case actionTypes.FETCH_PRESCRIPTION_DRUGS:
    return {
      ...state,
      reportsPrescriptionDrug: action.data

    }
  case actionTypes.FETCH_EXPERIENCE_PAIN:
    return {
      ...state,
      reportsExperiencePain: action.data
    } 

  case actionTypes.FETCH_PHYSICAL_EXAM:
    return {
      ...state,
      reportsPhysicalExamData: !_.isNull(action.data) ? calculatePhysicalFrequency(action.data.physical_exam_frequency) : [action.data],
      physicalExamDataAverage: !_.isNull(action.data) ? action.data : [action.data],
    } 

  case actionTypes.FETCH_DO_YOU_SMOKE:
    return {
      ...state,
      reportsSmokeData: !_.isNull(action.smokeData) ? calculateSmokeValue(action.smokeData) : [action.smokeData],
    } 

  case actionTypes.SET_COMPANY_BLOCK_DETAILS:
    return {
      ...state,
      companyBlockData: action.data
    }

  case actionTypes.SET_PEOPLE_COMPANY_LOCATION:
    return {
      ...state,
      companyPeopleLocation: action.locations
    }  
  
  case actionTypes.SET_PEOPLE_COMPANY_DEPARTMENT:
    return {
      ...state,
      companyPeopleDepartment: action.res
    }

  case actionTypes.SET_ENGAGEMENT_EMPLOYEE_COUNT:
    return {
      ...state,
      employeeCount: action.count
    }

  case actionTypes.SET_COMPANY:
    return {
      ...state,
      companyInfo: {
        id: action.id,
        name: action.name,
        companyStartDate: action.companyStartDate,
        showBiometricStatus: action.showBiometricStatus
      }
    };

  case actionTypes.GET_COMPANY:
    return {
      ...state,
      companyInfo: state.companyInfo
    };

  case actionTypes.NETWORK_ERROR:
    return {
      ...state,
      networkError: action.networkError
    };

  case actionTypes.GET_WELLNESS_CHAMPION:
    return {
      ...state,
      wellnessChampions: action.wellnessChampions
    };

  case actionTypes.FAILED_GET_WELLNESS_CHAMPION:
    return {
      ...state,
      wellnessChampionsError: action.wellnessChampionsError
    };
  
  case actionTypes.GET_WELLNESS_ADMIN:
    return {
      ...state,
      wellnessAdmin: action.wellnessAdmin
    };

  case actionTypes.FAILED_GET_WELLNESS_ADMIN:
    return {
      ...state,
      wellnessAdminError: action.wellnessAdminError
    };

  case actionTypes.GET_COMPANIES:
    return {
      ...state,
      companies: action.companies
    };

  case actionTypes.FAILED_GET_COMPANIES:
    return {
      ...state,
      companiesError: action.companiesError
    };

  case actionTypes.GET_TOP_CHALLENGES:
    return {
      ...state,
      topChallenges: action.topChallenges
    };

  case actionTypes.GET_TOP_EVENTS:
    return {
      ...state,
      topEvents: action.topEvents
    };

  case actionTypes.GET_NEW_USERS:
    return {
      ...state,
      newUsers: action.newUsers
    };

  case actionTypes.GET_MOST_ACTIVE_USERS:
    return {
      ...state,
      mostActiveUsers: action.mostActiveUsers
    };

  case actionTypes.GET_LEAST_ACTIVE_USERS:
    return {
      ...state,
      leastActiveUsers: action.leastActiveUsers
    };

  case actionTypes.GET_TOP_FIVE_FITNESS_GOALS:
    return {
      ...state,
      fitnessGoals: action.fitnessGoals
    };

  case actionTypes.GET_TOP_FIVE_DEVICES:
    return {
      ...state,
      topDevices: action.topDevices
    };

  case actionTypes.GET_TOP_FITNESS_ACTIVITIES:
    return {
      ...state,
      topActivities: action.topActivities
    };

  case actionTypes.GET_EXERCISE_PER_WEEK:
    return {
      ...state,
      weekExercise: action.weekExercise
    };

  case actionTypes.GET_MINUTES_ACTIVE_DETAILS:
    return {
      ...state,
      activeMinutes: action.activeMinutes
    };

  case actionTypes.GET_TOP_WELLNESS_INITIATIVES:
    return {
      ...state,
      wellnessInitiatives: action.wellnessInitiatives
    };

  case actionTypes.GET_COMPANY_INITIATIVES:
    return {
      ...state,
      companyInitiatives: action.companyInitiatives
    };
  
  case actionTypes.GET_GROUP_ACTIVITY_SUMMARY:
    return {
      ...state,
      groupActivitySummary: action.data
    };

  case actionTypes.BODY_MASS_PAYLOAD:
    return {
      ...state,
      bodyMassDetails: !_.isNull(action.data) ? calculateBodyMass(action.data) : [action.data],
      overWeightBodyMass : !_.isNull(action.data) ? action.data['overweight'] : null,
      obeseBodyMass: !_.isNull(action.data) ? action.data['obese'] : null,
      bodyMassFetching: false,
      bodyMassAveragePercentage:  !_.isNull(action.data) ? calculateBodyMassAveragePercentage(action.data) : [action.data],
    };

  case actionTypes.BAD_CHOLESTEROL:
    return {
      ...state,
      badCholesterolDetails: !_.isNull(action.data) ? calculateBadCholesterol(action.data) : null,
      badCholesterolFetch: false,
      badCholesterolAveragePercentage:!_.isNull(action.data) ? calculateBadCholesterolAveragePercentage(action.data) : null,
    };

  case actionTypes.GOOD_CHOLESTEROL:
    return {
      ...state,
      goodCholesterolDetails: !_.isNull(action.data) ? calculateGoodCholesterol(action.data) : null,
      goodCholesterolFetch: false,
      goodCholesterolAveragePercentage:!_.isNull(action.data) ? calculateGoodCholesterolAveragePercentage(action.data) : null,
    };

  case actionTypes.TRIGLYCERIDES_PAYLOAD:
    return{
      ...state,
      triglyceridesDetails: [action.data],
      triglyceridesFetch: false
    };
  
    // case actionTypes.Top_Health_PAYLOAD:
    //   return{
    //     ...state,
    //     topHealthDetails: [action.data],
    //     topHealthFetch: false
    //   };

  case actionTypes.AVERAGE_BIOMETRIC_DATA:
    return{
      ...state,
      averageBiometricDetail: !_.isNull(action.data) ? calculateAverageBiometric(action.data) : null,
      averageBiometricFetch: false
    };

  case actionTypes.BODY_MASS_FETCH:
    return{
      ...state,
      bodyMassFetching: true
    };

  case actionTypes.BAD_CHOLESTEROL_FETCH:
    return{
      ...state,
      badCholesterolFetch: true
    };

  case actionTypes.GOOD_CHOLESTEROL_FETCH:
    return{
      ...state,
      goodCholesterolFetch: true
    };

  case actionTypes.TRIGLYCERIDES_PAYLOAD_FETCH:
    return{
      ...state,
      triglyceridesFetch: true
    };

    // case actionTypes.Top_Health_FETCH:
    //   return{
    //     ...state,
    //     topHealthFetch: true
    //   };

  case actionTypes.AVERAGE_BIOMETRIC_DATA_FETCH:
    return{
      ...state,
      averageBiometricFetch: true
    };

  case actionTypes.GET_SYSTOLIC_BLO0DPRESSURE:
    return{
      ...state,
      systolicBloodPressureDetails: action.data
    };

  case actionTypes.GET_DIASTOLIC_BLO0DPRESSURE:
    return{
      ...state,
      diastolicBloodPressureDetails: action.data
    };
  case actionTypes.GET_FASTING_USER:
    return{
      ...state,
      fastingDataDetails: action.data
    };

  case actionTypes.GET_NON_FASTING_USER:
    return {
      ...state,
      nonFastingDetails: action.data
    }
  case actionTypes.GET_RECOGNITION_DETAILS:
    return {
      ...state,
      recognitionDetails: action.recognitionDetails
    }
  case actionTypes.GET_EMPLOYEES_OF_THE_MONTH:
    return {
      ...state,
      employeesOfTheMonthDetails:action.employeesOfTheMonthDetails,
    }
  case actionTypes.GET_EMPLOYEES_OF_THE_MONTHLYLY:
    return {
      ...state,
      employeesOfTheMonthlylyDetails:action.employeesOfTheMonthlylyDetails,
    }  
  case actionTypes.GET_EMPLOYEES_OF_THE_QUARTER:
    return {
      ...state,
      employeesOfTheMonthByQuarterlyDetails:action.employeesOfTheMonthByQuarterlyDetails,
    }  
  case actionTypes.GET_MOST_SOCIAL_USERS:
    return {
      ...state,
      mostSocialUsersDetails:action.mostSocialUsersDetails,
    }
  case actionTypes.FETCH_HURDLE_IN_EATING:
    return {
      ...state,
      hurdleInEatingData: action.data,
    }
  case actionTypes.FETCH_STRESS_CAUSING_FACTORS:
    return {
      ...state,
      stressCausingFactors: action.data,
    }
  case actionTypes.FETCH_FREQUENCY_FRUIT_EATING:
    return {
      ...state,
      frequencyOfEatingFreshFruits: action.data,
    }
  case actionTypes.FETCH_EATING_DRINKING_ADDEDSUGAR:
    return {
      ...state,
      frequencyOfEatingDrinkingAddedSugar: action.data,
    }
  case actionTypes.FETCH_STRESS_ABOUT_YOUR_FINANCES:
    return{
      ...state,
      frequencyOfStressAboutYourFinances: action.data,
    }
  case actionTypes.FETCH_RELAXING_DATA:
    return {
      ...state,
      frequencyOfRelaxingDetails: action.data,
    };
  case actionTypes.FETCH_HIGHLY_PROCESSED_FOOD_DETAILS:
    return{
      ...state,
      frequencyOfHighlyProcessedFoodDetails: action.data,
    };
  case actionTypes.FETCH_EATING_HEALTHY_DETAILS:
    return{
      ...state,
      frequencyOfEatingHealthyDetails: action.data,
    };
  case actionTypes.GET_RECOGNITION_ACTIVITY_DETAILS:
    return{
      ...state,
      getRecognitionActivityDetails: action.getRecognitionActivityDetails
    };
  case actionTypes.GET_TOP_RECOGNIZED_RECOGNITION_DETAILS:
    return{
      ...state,
      getTopRecognizedRecognitionDetails: action.getTopRecognizedRecognitionDetails
    };
  case actionTypes.GET_TOP_RECEIVERS_RECOGNITION_DETAILS:
    return{
      ...state,
      getTopReceiversRecognitionDetails: action.getTopReceiversRecognitionDetails
    };
  case actionTypes.GET_RECOGNITION_FOR_DEPARTMENT:
    return{
      ...state,
      getRecognitionDepartmentDetails: action.getRecognitionDepartmentDetails,
      recognitionLoading: false
    };

  case actionTypes.GET_RECOGNITION_FOR_LOCATIONS:
    return{
      ...state,
      getRecognitionLocationDetails: action.getRecognitionLocationDetails,
      recognitionLoading: false
    };

  case actionTypes.GET_RECOGNITION_FOR_ALL_DEPARTMENTS:
    return {
      ...state,
      getRecognitionForAllDepartmentsDetails: action.getRecognitionForAllDepartmentsDetails,
      isLoading: false
    }
  case actionTypes.GET_RECOGNITION_FOR_ALL_LOCATIONS:
    return {
      ...state,
      getRecognitionForAllLocationsDetails: action.getRecognitionForAllLocationsDetails,
      isLoading: false
    } 
  case actionTypes.RECOGNITION_LOADING:
    return {
      ...state,
      recognitionLoading: true
    }
  case actionTypes.IS_LOADING:
    return {
      ...state,
      isLoading: true
    }
  case actionTypes.SET_SELECTED_YEAR:
    return {
      ...state,
      selectedYearInfo: action.selectedYearInfo
    };
  case actionTypes.SET_SELECTED_YEARV2:
    return {
      ...state,
      selectedYearInfoV2: action.selectedYearInfoV2
    };
  case actionTypes.WELLBEING_LOADING:
    return {
      ...state,
      wellbeingLoading: true
    };
  case actionTypes.LIFE_STYLE_TAB_LOADING:
    return {
      ...state,
      lifeStyleLoading: true
    }
  case actionTypes.HEALTH_TAB_LOADING:
    return {
      ...state,
      healthTabLoading: true
    }
  case actionTypes.FITNESS_TAB_LOADING_TRUE:
    return {
      ...state,
      fitnessTabLoading: true
    }
  case actionTypes.FITNESS_TAB_LOADING_FALSE:
    return {
      ...state,
      fitnessTabLoading: false
    }
  case actionTypes.WELLBEING_LOADING_FALSE:
    return {
      ...state,
      wellbeingLoading: false
    };
  case actionTypes.HEALTH_TAB_LOADING_FALSE:
    return {
      ...state,
      healthTabLoading: false
    }
  case actionTypes.LIFE_STYLE_TAB_LOADING_FALSE:
    return {
      ...state,
      lifeStyleLoading: false
    }
  case actionTypes.GET_VIEW_MONTHLY_TASK_ICONS:
    return {
      ...state,
      monthlyTaskIcons: action.monthlyTaskIcons
    }
  case actionTypes.GET_ADMIN_MONTHLY_TASKS:
    return {
      ...state,
      adminMonthlyTasks: action.adminMonthlyTasks,
      isLoading: false
    }
  case actionTypes.GET_USER_TASK_STATUS:
    return {
      ...state,
      deleteTaskStatus: action.deleteTaskStatus,
      isLoading: false
    }
  case actionTypes.SHOW_BIOMETRIC_STATUS:
    return{
      ...state,
      showBiometricStatus: action.showBiometricStatus
    }
  case actionTypes.GET_COMPANY_RECOGNITIONS:
    return{
      ...state,
      getCompanyRecognitions: action.getCompanyRecognitions
    };
  case actionTypes.GET_COMAPANY_REWARD_PROGRAMS:
    return{
      ...state,
      companyRewardPrograms: action.companyRewardPrograms
    };
  case actionTypes.GET_REWARDSREPORT_REPORT:
    return{
      ...state,
      rewardsReport: action.rewardsReport
    };
  case actionTypes.GET_USER_LANGUAGE:
    return{
      ...state,
      userLanguage: action.userLanguage
    };
  case actionTypes.FITNESS_LEADERBOARD_DATA:{
    let distanceResponse = [];
    let caloriesResponse = [];
    let stepsResponse = [];
    let response = [];
    if (action.pageNumber === 1) {
      distanceResponse = action.fitnessLeaderboard['distance'];
      caloriesResponse = action.fitnessLeaderboard['calories'];
      stepsResponse = action.fitnessLeaderboard['steps'];
      response = _.isNull(action.fitnessLeaderboard) ? [] : action.fitnessLeaderboard;
    } else {
      let DistanceData = _.isNull(action.fitnessLeaderboard['distance']) ? [] : action.fitnessLeaderboard['distance'];
      let CaloriesData = _.isNull(action.fitnessLeaderboard['calories']) ? [] : action.fitnessLeaderboard['calories'];
      let StepsData = _.isNull(action.fitnessLeaderboard['steps']) ? [] : action.fitnessLeaderboard['steps'];
      distanceResponse=[
        ...state.distanceLeaderboard,
        ...DistanceData
      ]
      caloriesResponse=[
        ...state.caloriesLeaderboard,
        ...CaloriesData
      ]
      stepsResponse=[
        ...state.stepsLeaderboard,
        ...StepsData
      ]
      let data = _.isNull(action.fitnessLeaderboard) ? [] : action.fitnessLeaderboard;
      response = [
        state.fitnessLeaderboard,
        data
      ];
    }
    return {
      ...state,
      fitnessLeaderboard: response,
      distanceLeaderboard: distanceResponse,
      caloriesLeaderboard: caloriesResponse,
      stepsLeaderboard: stepsResponse
    };
  }
  case actionTypes.SOCIAL_USERS_DATA:{
    let response = [];
    if (action.pageNumber === 1) {
      response = _.isNull(action.socialUserData) ? [] : action.socialUserData;
    } else {
      let data = _.isNull(action.socialUserData) ? [] : action.socialUserData;
      response = [
        ...state.socialUserData,
        ...data
      ];
    }
    return {
      ...state,
      socialUserData: response,
    };
  }

  case actionTypes.FITNESS_USERS_DATA:{
    let response = [];
    if (action.pageNumber === 1) {
      response = _.isNull(action.fitnessUserData) ? [] : action.fitnessUserData;
    } else {
      let data = _.isNull(action.fitnessUserData) ? [] : action.fitnessUserData;
      response = [
        ...state.fitnessUserData,
        ...data
      ];
    }
    return {
      ...state,
      fitnessUserData: response,
    };
  }

  case actionTypes.WELLNESS_USERS_DATA:{
    let response = [];
    if (action.pageNumber === 1) {
      response = _.isNull(action.wellnessUserData) ? [] : action.wellnessUserData;
    } else {
      let data = _.isNull(action.wellnessUserData) ? [] : action.wellnessUserData;
      response = [
        ...state.wellnessUserData,
        ...data
      ];
    }
    return {
      ...state,
      wellnessUserData: response,
    };
  }

  case actionTypes.COMPANY_TEAM_POINTS:{
    let response = [];
    if (action.pageNumber === 1) {
      response = _.isNull(action.companyTeamPoints) ? [] : action.companyTeamPoints;
    } else {
      let data = _.isNull(action.companyTeamPoints) ? [] : action.companyTeamPoints;
      response = [
        ...state.companyTeamPoints,
        ...data
      ];
    }
    return {
      ...state,
      companyTeamPoints: response,
    };
  }

  case actionTypes.TEAM_USERS_POINTS:
    return {
      ...state,
      teamUsersPoints: action.teamUsersPoints,
    }


  case actionTypes.TEAM_ICONS:
    return{
      ...state,
      teamIconsData: action.teamIconsData
    }; 

  case actionTypes.GET_COMPANY_TEAM_COUNT:
    return{
      ...state,
      teamsCount: action.teamsCount
    };

  case actionTypes.GET_COMPANY_TEAM_AND_MY_TEAM_FLAGS:
    return{
      ...state,
      wellnessCount: action.wellnessCount
    };  

    

  case actionTypes.TOP_USER_TOTAL_POINTS:{
    let response = [];
    if (action.pageNumber === 1) {
      response = _.isNull(action.topUsersTotalPoints) ? [] : action.topUsersTotalPoints;
    } else {
      let data = _.isNull(action.topUsersTotalPoints) ? [] : action.topUsersTotalPoints;
      response = [
        ...state.topUsersTotalPoints,
        ...data
      ];
    }
    return {
      ...state,
      topUsersTotalPoints: response,
    };
  }
  case actionTypes.USER_RANK:
    return {
      ...state,
      userRank: action.userRank
    };
  case actionTypes.GET_TIP_BY_CATEGORY:
    return{
      ...state,
      dailyTipData: action.dailyTipData
    };
  case actionTypes.TIP_CATEGORY_COUNT:
    return{
      ...state,
      tipCategoryCount: action.tipCategoryCount
    };
  case actionTypes.GET_TIP_BY_ID:
    return{
      ...state,
      dailytipData: action.dailytipData
    };
  case actionTypes.GET_TODAY_TIP:
    return{
      ...state,
      todayTipData: action.todayTipData
    };
  case actionTypes.TIP_QUOTE_COUNT:
    return{
      ...state,
      tipQuoteCount: action.tipQuoteCount
    };
  case actionTypes.GET_LANGUAGES:
    return{
      ...state,
      getLanguages: action.getLanguages
    };
  case actionTypes.GET_RECOGNITION_MONTHLY:
    return{
      ...state,
      monthlyRecognitionData: action.monthlyRecognitionData
    };
  case actionTypes.GET_COMPANY_INFORMATION:
    return{
      ...state,
      companyInfoData: action.companyInfoData
    };
  case actionTypes.USER_TOTAL_POINTS:
    return{
      ...state,
      userTotalPoints: action.userTotalPoints
    };
  default:
    break;
  }
  return state;
};

export default reducer;